import React from 'react';
import { TextField } from '@material-ui/core';
import '../Constants/tableView.css';
import PropTypes from 'prop-types';

const OpenResponseQuestion = (props) => {
    const { question, responses, setResponses, index } = props;

    return (
        <div className="Row">
            <TextField
                id="outlined-multiline-static"
                multiline
                fullWidth
                rows={4}
                variant="outlined"
                onChange={(e) => {
                    const r = responses;
                    r[index] = {
                        ...responses[index],
                        open_response_value: e.target.value,
                    };
                    setResponses(r);
                }}
            />
        </div>
    );
};

OpenResponseQuestion.propTypes = {
    question: PropTypes.object.isRequired,
};

export default OpenResponseQuestion;
