import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, List, ListItem, TextField } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import universalFetch from '../../Functions/UniversalFetch/UniversalFetch';
import FormPageContainer from '../FormPageContainer/FormPageContainer';
import FormButtonContents from '../FormButtonContents/FormButtonContents';
import Alert from '../Alert/Alert';
import { getFormattedName } from '../../Functions/getFormattedName';
import { endpoints } from '../../Constants/Config';
import { universalPost } from '../../Functions/UniversalPost/UniversalPost';
import ConfirmRiskModal from '../ConfirmRiskModal';

const useStyles = makeStyles((theme) => ({
    card: {
        width: window.innerWidth / 2,
        height: window.innerHeight * 0.7,
        overflow: 'auto',
        backgroundColor: '#fafffb',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerIcon: {
        paddingRight: theme.spacing(2),
    },
    newFormButton: {
        color: '#ccc',
    },
    searchBar: {
        width: window.innerWidth / 2,
    },
}));

const RiskList = () => {
    const classes = useStyles();
    const [riskFormOpen, setRiskFormOpen] = React.useState(false);
    const [participant, setParticipant] = React.useState(null);
    const [filteredName, setFilteredName] = React.useState('');
    const [participants, setParticipants] = React.useState({
        data: null,
        loading: false,
        error: null,
    });
    const [alertStatus, setAlertStatus] = React.useState({
        isOpen: false,
        isError: false,
        text: '',
    });
    const [createRiskResponse, setCreateRiskResponse] = React.useState({
        data: null,
        loading: false,
        error: null,
    });
    const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
    useEffect(() => {
        universalFetch(
            setParticipants,
            `${
                endpoints.allParticipants
            }?organization_id=${sessionStorage.getItem('organizationID')}`,
            (errorMessage) => {
                setAlertStatus({
                    isOpen: true,
                    isError: true,
                    text: 'Participants not found. ' + errorMessage,
                });
            },
            () => {}
        );
    }, [createRiskResponse.data]);

    const handleOpenRiskForm = () => {
        if (participant.risk_assessment) {
            setRiskFormOpen(true);
        } else {
            universalPost(
                setCreateRiskResponse,
                endpoints.createRisk,
                {
                    organization_id: sessionStorage.getItem('organizationID'),
                    participant_id: participant.id,
                    reviewer_id: sessionStorage.getItem('reviewerID'),
                },
                () => {
                    alert('Failed to create risk assesment');
                },
                () => {
                    setParticipant(participant);
                    setRiskFormOpen(true);
                }
            );
        }
    };

    // TODO: edit skeleton components to more closely mimic size of a participant accordian component
    let header = (
        <>
            <br />
            <Typography variant="h5">Risk Assessment Forms</Typography>
            <br />
            <TextField
                fullWidth
                className={classes.searchBar}
                variant="outlined"
                margin="normal"
                align="center"
                placeholder="Search for Participant"
                onChange={(e) => setFilteredName(e.target.value)}
            />
            <br />
        </>
    );

    if (participants.loading) {
        return (
            <div className={classes.root}>
                {header}
                <Card className={classes.card}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Card>
            </div>
        );
    }
    if (participants.error) {
        return (
            <div className={classes.root}>
                {header}
                <Card className={classes.card}>
                    <Alert
                        alertStatus={alertStatus}
                        setAlertStatus={setAlertStatus}
                    />
                </Card>
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                {header}
                <Card className={classes.card}>
                    <List>
                        {participants.data !== null &&
                            participants.data
                                .filter(
                                    (option) =>
                                        String(
                                            getFormattedName(
                                                option.first_name,
                                                option.last_name
                                            )
                                        ).includes(
                                            filteredName.toLowerCase()
                                        ) &&
                                        (option.risk_assessment === null ||
                                            (option.risk_assessment &&
                                                option.risk_assessment.responses
                                                    .length === 0))
                                )
                                .map((participant) => (
                                    <ListItem
                                        key={participant.id}
                                        button
                                        onClick={() => {
                                            setParticipant(participant);
                                            setConfirmModalOpen(true);
                                        }}
                                    >
                                        <FormButtonContents
                                            formName={`${participant.first_name} ${participant.last_name}`}
                                            formExists={true}
                                        />
                                    </ListItem>
                                ))}
                    </List>
                </Card>
                {participant !== null && (
                    <FormPageContainer
                        formOpen={riskFormOpen}
                        setFormOpen={setRiskFormOpen}
                        firstName={participant.first_name}
                        lastName={participant.last_name}
                        id={participant.id}
                    />
                )}
                {participant && (
                    <ConfirmRiskModal
                        setModalOpen={setConfirmModalOpen}
                        modalOpen={confirmModalOpen}
                        participantName={`${participant.first_name} ${participant.last_name}`}
                        handleCreateSurvey={handleOpenRiskForm}
                    />
                )}
            </div>
        );
    }
};

export default RiskList;
