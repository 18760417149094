export default {
    'School Factors': [
        'Truancy',
        'Detention in School',
        'Suspension or Expulsion from School',
        'Grade Retention (Held Back / Failing a Grade)',
        'Dropping Out of School',
        'Were not proficient on MCAS',
    ],
    'Home Factors': [
        'Family Court Involvement',
        'Family DCF Involvement',
        'Family Poverty',
        'Substance Use in the Home',
        'History of Domestic Violence in the Home',
        'History of Abuse',
        'Not Living with a Parent',
        'Homeless',
        'Limited Parental Supervision (e.g., Out at Night)',
    ],
    'Gang / Youth Violence Factors': [
        'Involvement with Known Gang Members',
        'A Family Member who has Involvement with Known Gang Members',
        'Fighting in or out of School / Physically Attacking Another Person',
        'Any Prior or Current Court Involvement',
        'Steals, Lies, Verbally Abusive, Defiant, No Trust of Adults (Any One of these)',
        'Owns or Carries a Weapon',
        'Hangs out with a Click or a Set or a Crew or Boys',
        'Drinks Alcohol',
        'Uses or Possesses Illicit Substances',
    ],
    'Peers and Other': [
        'Hangs Around with Negative Peers',
        'Is a Teen Mother or Father',
        'Has Been a Victim of Assault or Abuse',
        'Gets in Trouble with Negative Peers',
    ],
};
