import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import RadioRow from '../RadioButtonGroup/RadioButtonsGroup';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));

// An individual page of the risk assesment form
const FormPage = (props) => {
    const classes = useStyles();
    const { handleUpdateRiskValues, title, questions, riskValues } = props;

    // Returns a row of radio buttons for a given question
    const getRadioRow = (question, index) => {
        return (
            <React.Fragment key={index}>
                <br />
                <br />
                <RadioRow
                    title={title}
                    question={question}
                    index={index}
                    riskValues={riskValues}
                    handleUpdateRiskValues={handleUpdateRiskValues}
                />
            </React.Fragment>
        );
    };

    return (
        <div className={classes.root}>
            <Typography variant="h5">{title}</Typography>
            {questions.map((question, index) => getRadioRow(question, index))}
        </div>
    );
};

FormPage.propTypes = {
    questions: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    handleUpdateRiskValues: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
};

export default FormPage;
