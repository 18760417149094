export const raceOptions = [
    'American Indian or Alaska Native',
    'Asian',
    'Black or African American',
    'Native Hawaiian or Other Pacific Islander',
    'Latin',
    'White',
    'Other',
];

export const ethnicityOptions = [
    'Hispanic or Latino',
    'Not Hispanic or Latino',
];

export const gradeOptions = [
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
    'Not in School',
];

export const genderOptions = [
    'Male',
    'Female',
    'Transgender Male',
    'Transgender Female',
    'Non-binary/Genderqueer',
    'Other',
    'Unknown',
];

export const mentalHealthOptions = ['Yes', 'No', 'Unknown'];

export const gangOptions = ['Yes', 'No'];

export const disabilityOptions = ['Unknown', 'N/A'];
