import React, { useEffect } from 'react';
import { makeStyles, Container, Typography, Button } from '@material-ui/core';
import EditableParticipant from '../EditableParticipant/EditableParticipant';
import { universalPost } from '../../Functions/UniversalPost/UniversalPost';
import universalFetch from '../../Functions/UniversalFetch/UniversalFetch';
import { endpoints } from '../../Constants/Config';
import Alert from '../Alert/Alert';
import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2),
        align: 'center',
    },
}));

// Page that generates survey Report
const GenerateSurveyReport = () => {
    const classes = useStyles();

    const [alertStatus, setAlertStatus] = React.useState({
        isOpen: false,
        isError: false,
        text: '',
    });
    const [fetchOrganizationsStatus, setFetchOrganizationsStatus] =
        React.useState({
            data: null,
            loading: false,
            error: null,
        });
    const [surveyReports, setSurveyReports] = React.useState({
        data: null,
        loading: false,
        error: null,
    });

    //Fetching all the organizations that were involved in the survey
    useEffect(() => {
        universalFetch(
            setFetchOrganizationsStatus,
            endpoints.allOrganizations,
            () => {},
            () => {}
        );
    }, []);

    //Sending a post request to generate survey reports and get the file stream of all the csv reports
    useEffect(() => {
        if (fetchOrganizationsStatus.data != null) {
            console.log('I cam calling the generate report');
            console.log(fetchOrganizationsStatus.data);
            universalPost(
                setSurveyReports,
                endpoints.surveyReport,
                { organizations: fetchOrganizationsStatus.data },
                (errorMessage) => {
                    setAlertStatus({
                        isOpen: true,
                        isError: true,
                        text: 'Surveys were not generated. ' + errorMessage,
                    });
                },
                () => {
                    setAlertStatus({
                        isOpen: true,
                        isError: false,
                        text: 'Surveys generated successfully!',
                    });
                },
                {
                    responseType: 'arraybuffer',
                }
            );
        }
    }, [fetchOrganizationsStatus.data]);

    // Function to save the file stream locally as a zip file through the browser
    const saveFile = () => {
        console.log('Saving the file in the zip format');
        let extension = 'zip';
        let tempFileName = `survery_reports`;
        let fileName = `${tempFileName}.${extension}`;
        const blob = new Blob([surveyReports.data], {
            type: 'octet/stream',
        });
        saveAs(blob, fileName);
    };

    return (
        <Container component="main" maxWidth="sm">
            <div className={classes.paper}>
                <form className={classes.form}>
                    <Typography align="center">
                        Shannon Survey Reports
                    </Typography>
                </form>
                <br />
                <Alert
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
                <br />
                <br />
                <Button variant="contained" color="primary" onClick={saveFile}>
                    Download Survey Reports
                </Button>
                <br />
                <br />
            </div>
        </Container>
    );
};

export default GenerateSurveyReport;
