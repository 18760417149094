import React from 'react';
import { ListItem } from '@material-ui/core';
import QuestionComponent from './QuestionComponent';

const SurveyContents = (props) => {
    const { questions, responses, setResponses } = props;

    return (
        <div>
            {questions.map((question, index) => (
                <ListItem key={question.id}>
                    <QuestionComponent
                        question={question}
                        index={index}
                        responses={responses}
                        setResponses={setResponses}
                    />
                </ListItem>
            ))}
        </div>
    );
};

export default SurveyContents;
