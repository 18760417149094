import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
} from '@material-ui/core';

const SubmitModal = (props) => {
    const {
        modalOpen,
        setModalOpen,
        handleSendResponses,
        participantName,
    } = props;
    const handleContinue = () => {
        setModalOpen(false);
        handleSendResponses();
    };
    return (
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
            <DialogTitle>{'Are you sure?'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    By clicking continue, you acknowledge that you will be
                    submitting the youth outcomes survey for{' '}
                    <b>{participantName}</b> The survey will not be able to be
                    edited after you submit. Are you sure you want to continue?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setModalOpen(false)}>
                    Go Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleContinue}
                >
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SubmitModal;
