import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, ListItem, TextField } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import universalFetch from '../../Functions/UniversalFetch/UniversalFetch';
import { universalPost } from '../../Functions/UniversalPost/UniversalPost';
import ConfirmModal from '../ConfirmModal';
import FormButtonContents from '../FormButtonContents/FormButtonContents';
import Alert from '../Alert/Alert';
import { getFormattedName } from '../../Functions/getFormattedName';
import { endpoints } from '../../Constants/Config';
import SurveyComponentModal from '../SurveyComponentModal.js';

const useStyles = makeStyles((theme) => ({
    card: {
        width: window.innerWidth / 2,
        height: window.innerHeight * 0.7,
        overflow: 'auto',
        backgroundColor: '#fafffb',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerIcon: {
        paddingRight: theme.spacing(2),
    },
    newFormButton: {
        color: '#ccc',
    },
    searchBar: {
        width: window.innerWidth / 2,
    },
}));

const YouthOutcomesPreview = () => {
    const classes = useStyles();
    const [surveyOpen, setSurveyOpen] = React.useState(false);
    const [filteredName, setFilteredName] = React.useState('');
    const [participant, setParticipant] = React.useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [alertStatus, setAlertStatus] = React.useState({
        isOpen: false,
        isError: false,
        text: '',
    });
    const [participants, setParticipants] = React.useState({
        data: null,
        loading: false,
        error: null,
    });
    const [createSurveyResponse, setCreateSurveyResponse] = React.useState({
        data: null,
        loading: false,
        error: null,
    });
    useEffect(() => {
        universalFetch(
            setParticipants,
            `${
                endpoints.allParticipants
            }?organization_id=${sessionStorage.getItem('organizationID')}`,
            (errorMessage) => {
                setAlertStatus({
                    isOpen: true,
                    isError: true,
                    text: 'Failed to fetch particpants. ' + errorMessage,
                });
            },
            () => {
                setAlertStatus({ ...alertStatus, isOpen: false });
            }
        );
    }, [surveyOpen]);

    const handleCreateSurvey = () => {
        if (participant.survey) {
            universalFetch(
                setCreateSurveyResponse,
                `/survey/participant/?participant_id=${participant.id}`,
                (errorMessage) => {
                    setAlertStatus({
                        isOpen: true,
                        isError: true,
                        text: 'Survey found. ' + errorMessage,
                    });
                },
                () => {
                    setSurveyOpen(true);
                }
            );
        } else {
            universalPost(
                setCreateSurveyResponse,
                endpoints.survey,
                {
                    organization_id: sessionStorage.getItem('organizationID'),
                    participant_id: participant.id,
                    reviewer_id: sessionStorage.getItem('reviewerID'),
                },
                (errorMessage) => {
                    setAlertStatus({
                        isOpen: true,
                        isError: true,
                        text: 'Survey was not created. ' + errorMessage,
                    });
                },
                () => {
                    setAlertStatus({
                        isOpen: true,
                        isError: false,
                        text: 'Survey created successfully!',
                    });
                    setSurveyOpen(true);
                }
            );
        }
    };

    let header = (
        <>
            <br />
            <Typography variant="h5">
                Surveys that need to be completed
            </Typography>
            <br />
            <TextField
                fullWidth
                className={classes.searchBar}
                variant="outlined"
                margin="normal"
                align="center"
                placeholder="Search for Participant"
                onChange={(e) => setFilteredName(e.target.value)}
            />
            <br />
        </>
    );

    // TODO: edit skeleton components to more closely mimic size of a participant accordian component
    if (participants.loading) {
        return (
            <div className={classes.root}>
                {header}
                <Card className={classes.card}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Card>
            </div>
        );
    }
    if (participants.error) {
        return (
            <div className={classes.root}>
                {header}
                <Card className={classes.card}>
                    <Alert
                        alertStatus={alertStatus}
                        setAlertStatus={setAlertStatus}
                    />
                </Card>
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                {header}
                <Card className={classes.card}>
                    {participants.data !== null &&
                        participants.data
                            .filter(
                                (option) =>
                                    String(
                                        getFormattedName(
                                            option.first_name,
                                            option.last_name
                                        )
                                    ).includes(filteredName.toLowerCase()) &&
                                    (option.survey === null ||
                                        (option.survey &&
                                            option.survey.responses.length ===
                                                0))
                            )
                            .map((participant) => (
                                <ListItem
                                    key={participant.id}
                                    button
                                    onClick={() => {
                                        setParticipant(participant);
                                        setModalOpen(true);
                                    }}
                                >
                                    <FormButtonContents
                                        formName={
                                            participant.first_name +
                                            ' ' +
                                            participant.last_name
                                        }
                                        formExists={true}
                                    />
                                </ListItem>
                            ))}
                </Card>
                {/* The two current forms a particpant can have. Rendered here because they are a Dialog rather than a new page that gets loaded */}
                {participant && (
                    <ConfirmModal
                        setModalOpen={setModalOpen}
                        modalOpen={modalOpen}
                        participantName={`${participant.first_name} ${participant.last_name}`}
                        handleCreateSurvey={handleCreateSurvey}
                    />
                )}
                {createSurveyResponse.data && (
                    <SurveyComponentModal
                        questions={
                            createSurveyResponse.data.questions ??
                            createSurveyResponse.data[0].questions
                        }
                        surveyID={
                            createSurveyResponse.data.id ??
                            createSurveyResponse.data[0].id
                        }
                        formOpen={surveyOpen}
                        setFormOpen={setSurveyOpen}
                        participantName={`${participant.first_name} ${participant.last_name}`}
                    />
                )}
            </div>
        );
    }
};

export default YouthOutcomesPreview;
