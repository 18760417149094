import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Slide,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import OptionalDateOfBirth from '../OptionalDateOfBirth/OptionalDateOfBirth';
import {
    raceOptions,
    ethnicityOptions,
    genderOptions,
    disabilityOptions,
    gradeOptions,
    gangOptions,
    mentalHealthOptions,
} from '../../Constants/ParticipantInfoDropDownText';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    input: {
        paddingBottom: theme.spacing(4),
    },
}));

// Page that edits a participant passed in from the parent
const EditableParticipant = (props) => {
    const { participantInfo, setParticipantInfo, organizations } = props;
    const classes = useStyles();
    const [DOBKnown, setDOBKnown] = React.useState(true);

    function updateDOBStatus(newState) {
        if (newState === true) {
            setParticipantInfo({
                ...participantInfo,
                age: null,
                date_of_birth: participantInfo.date_of_birth,
            });
            setDOBKnown(true);
        } else {
            setParticipantInfo({
                ...participantInfo,
                date_of_birth: null,
                age: participantInfo.age,
            });
            setDOBKnown(false);
        }
    }

    return (
        <>
            <TextField
                fullWidth
                label="First Name"
                className={classes.input}
                autoFocus
                required
                value={participantInfo.first_name}
                onChange={(e) =>
                    setParticipantInfo({
                        ...participantInfo,
                        first_name: e.target.value,
                    })
                }
            />
            <br />
            <TextField
                fullWidth
                required
                label="Last Name"
                className={classes.input}
                value={participantInfo.last_name}
                onChange={(e) =>
                    setParticipantInfo({
                        ...participantInfo,
                        last_name: e.target.value,
                    })
                }
            />
            <br />
            <OptionalDateOfBirth
                participantInfo={participantInfo}
                setParticipantInfo={setParticipantInfo}
                checkboxState={DOBKnown}
                setCheckboxState={updateDOBStatus}
            />
            <br />
            <FormControl fullWidth className={classes.input}>
                <InputLabel required>Gender</InputLabel>
                <Select
                    value={participantInfo.gender}
                    onChange={(e) =>
                        setParticipantInfo({
                            ...participantInfo,
                            gender: e.target.value,
                        })
                    }
                >
                    {genderOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br />
            <FormControl fullWidth className={classes.input}>
                <InputLabel required>Race</InputLabel>
                <Select
                    value={participantInfo.race}
                    onChange={(e) =>
                        setParticipantInfo({
                            ...participantInfo,
                            race: e.target.value,
                        })
                    }
                >
                    {raceOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br />
            <FormControl fullWidth className={classes.input}>
                <InputLabel required>Ethnicity</InputLabel>
                <Select
                    value={participantInfo.ethnicity}
                    onChange={(e) =>
                        setParticipantInfo({
                            ...participantInfo,
                            ethnicity: e.target.value,
                        })
                    }
                >
                    {ethnicityOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br />
            <TextField
                fullWidth
                label="Address"
                className={classes.input}
                value={participantInfo.address}
                onChange={(e) =>
                    setParticipantInfo({
                        ...participantInfo,
                        address: e.target.value,
                    })
                }
            />
            <br />
            <TextField
                fullWidth
                label="School Name"
                className={classes.input}
                value={participantInfo.school_name}
                onChange={(e) =>
                    setParticipantInfo({
                        ...participantInfo,
                        school_name: e.target.value,
                    })
                }
            />
            <br />
            <FormControl fullWidth className={classes.input}>
                <InputLabel>School Grade</InputLabel>
                <Select
                    value={participantInfo.grade}
                    onChange={(e) =>
                        setParticipantInfo({
                            ...participantInfo,
                            grade: e.target.value,
                        })
                    }
                >
                    {gradeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br />
            <FormControl required fullWidth className={classes.input}>
                <InputLabel>Gang Involvement</InputLabel>
                <Select
                    value={participantInfo.gang_involvement}
                    onChange={(e) =>
                        setParticipantInfo({
                            ...participantInfo,
                            gang_involvement: e.target.value,
                        })
                    }
                >
                    {['Yes', 'No'].map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Autocomplete
                className={classes.input}
                freeSolo
                onChange={(e, v) =>
                    setParticipantInfo({
                        ...participantInfo,
                        disability: v,
                    })
                }
                options={disabilityOptions.map((option) => option)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Disability (type what disability if applicable)"
                    />
                )}
            />
            <FormControl fullWidth className={classes.input}>
                <InputLabel>Has Mental Health Need</InputLabel>
                <Select
                    value={participantInfo.mental_health}
                    onChange={(e) =>
                        setParticipantInfo({
                            ...participantInfo,
                            mental_health: e.target.value,
                        })
                    }
                >
                    {mentalHealthOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br />
            <FormControl fullWidth className={classes.input}>
                <InputLabel required>Organization</InputLabel>
                <Select
                    value={participantInfo.organzation_id}
                    onChange={(e) =>
                        setParticipantInfo({
                            ...participantInfo,
                            organization_id: e.target.value,
                        })
                    }
                >
                    {organizations &&
                        organizations.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <br />
        </>
    );
};

export default EditableParticipant;
