import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import roles from '../../Constants/Roles';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: '#FFF',
        fontSize: 30,
    },
    drawer: {
        width: 250,
        height: '100%',
    },
    title: {
        flexGrow: 1,
    },
}));

const role = sessionStorage.getItem('role');

const menuItems = [
    {
        listIcon: <HomeIcon />,
        listText: 'Home',
        link: '/home',
    },
    {
        listIcon: <FolderSharedIcon />,
        listText: 'Risk Assesment Forms',
        link: '/risk-assesments',
    },
    {
        listIcon: <FolderIcon />,
        listText: 'Youth Outcome Forms',
        link: '/youth-outcomes',
    },
    {
        listIcon: <PersonIcon />,
        listText: 'Participant Information',
        link: '/participants',
    },
    role === roles.ADMIN && {
        listIcon: <AddIcon />,
        listText: 'Create Participant',
        link: '/create-participant',
    },
    role === roles.ADMIN && {
        listIcon: <AddIcon />,
        listText: 'Add Survey Question',
        link: '/add-questions',
    },
    role === roles.ADMIN && {
        listIcon: <AddIcon />,
        listText: 'Create Organization',
        link: '/create-organization',
    },
    role === roles.ADMIN && {
        listIcon: <AddIcon />,
        listText: 'Create User',
        link: '/add-user',
    },
    role === roles.ADMIN && {
        listIcon: <AddIcon />,
        listText: 'Generate Survey Report',
        link: '/generate-survey-report',
    },
];

const Navbar = () => {
    const classes = useStyles();
    const [menuOpen, setMenuOpen] = useState(false);
    const role = sessionStorage.getItem('role');

    const logout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('role');
        window.location.reload();
    };

    const menuList = () => (
        <Box component="div" className={classes.drawer}>
            <List>
                {menuItems.map((item, key) => (
                    <ListItem
                        button
                        key={key}
                        onClick={() => window.location.replace(item.link)}
                    >
                        <ListItemIcon>{item.listIcon}</ListItemIcon>
                        <ListItemText>{item.listText}</ListItemText>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <Box component="nav">
                <AppBar position="static">
                    <Toolbar>
                        <IconButton onClick={() => setMenuOpen(true)}>
                            <MenuIcon
                                className={classes.iconButton}
                                size="medium"
                            ></MenuIcon>
                        </IconButton>

                        <Drawer
                            open={menuOpen}
                            onClose={() => setMenuOpen(false)}
                        >
                            {menuList()}
                        </Drawer>
                        <Typography
                            aria-label="This is a test"
                            variant="h6"
                            className={classes.title}
                        >
                            Project SHANNON
                        </Typography>
                        <Button onClick={logout} color="inherit">
                            Logout
                        </Button>
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
};

export default Navbar;
