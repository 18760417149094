import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import allReducers from './Reducers';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { isDev } from './Constants/Config';
import { Amplify } from 'aws-amplify';

const store = createStore(
    allReducers,
    isDev &&
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
);

const theme = createMuiTheme({
    typography: {
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        // type: 'dark',
        primary: {
            main: '#2c780b',
        },
    },
});

// To confirm, this doesnt need to be in the gitignore. For reference:
// https://stackoverflow.com/questions/62703095/are-aws-cognito-user-pool-id-and-app-client-id-secret
Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: 'us-east-2',
        userPoolId: isDev ? 'us-east-2_aq3dAQmQZ' : 'us-east-2_NN5hIykX2',
        userPoolWebClientId: isDev
            ? '2splpomb1cam6dorlmose99cu'
            : '441a2shopn8sbasbmlcsjcpjjg',
    },
});

ReactDOM.render(
    <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router>
                <>
                    <MuiThemeProvider theme={theme}>
                        <App />
                    </MuiThemeProvider>
                </>
            </Router>
        </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
