import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

const useStyles = makeStyles(() => ({
    newFormButton: {
        color: '#ccc',
    },
}));

// Dispays the folder icon and text in black if the form exists for a participant, otherwise shows the add icon and text in grey
const FormButtonContents = (props) => {
    const { formName, formExists } = props;
    const classes = useStyles();

    return (
        <>
            {formExists && (
                <>
                    <ListItemIcon>
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={formName} />
                </>
            )}
            {!formExists && (
                <>
                    <ListItemIcon>
                        <NoteAddIcon className={classes.newFormButton} />
                    </ListItemIcon>
                    <ListItemText
                        className={classes.newFormButton}
                        primary={formName}
                    />
                </>
            )}
        </>
    );
};

FormButtonContents.propTypes = {
    formName: PropTypes.string,
    formExists: PropTypes.bool,
};

export default FormButtonContents;
