import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    TextField,
    Container,
    FormControl,
    FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../Media/CYE_Logo.png';
import { Auth } from 'aws-amplify';
import { baseURL } from '../../Constants/Config';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2),
        align: 'center',
    },
}));

// The login page
export default function SignIn() {
    // sets styling
    const classes = useStyles();

    // Email and password from the textbox
    const [username, setUsername] = useState(''); // Stores the user's email
    const [password, setPassword] = useState(''); // Stores the user's password
    const [isValid, setIsValid] = useState(true);

    // keyCode 13 is the enter/return key
    const enterKeyPress = useCallback((event) => {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    }, []);

    // Checks if the enter key is pressed
    useEffect(() => {
        document.addEventListener('keydown', enterKeyPress, false);

        return () => {
            document.removeEventListener('keydown', enterKeyPress, false);
        };
    }, [enterKeyPress]);

    // Sets the token and reloads the page. Will become much more robust once login is implimented on the server side
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const user = await Auth.signIn(username, password);
            if (user.signInUserSession.accessToken !== undefined) {
                var authToken = user.signInUserSession.idToken.jwtToken;
                var base64Url = authToken.split('.')[1];
                var json = JSON.parse(window.atob(base64Url));
                // console.log(user.signInUserSession.idToken.jwtToken);

                const reviewer = await axios
                    .get(
                        `${baseURL}/info/staff/?first_name=${username}&organization_id=${json['custom:organization_id']}`,
                        {
                            headers: {
                                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
                            },
                        }
                    )
                    .catch((err) => {
                        alert(err.message);
                        return;
                    });
                //console.log(reviewer);
                sessionStorage.setItem('reviewerID', reviewer.data.id, {
                    path: '/',
                });
                sessionStorage.setItem('username', username, { path: '/' });
                sessionStorage.setItem(
                    'token',
                    user.signInUserSession.idToken.jwtToken,
                    { path: '/' }
                );
                sessionStorage.setItem('role', json['custom:role'], {
                    path: '/',
                });
                sessionStorage.setItem(
                    'organizationID',
                    json['custom:organization_id'],
                    { path: '/' }
                );
                window.location.reload();
            }
        } catch (error) {
            setIsValid(false);
            console.log(error);
        }
    };

    // Checks if the user put in an email and password
    function validateForm() {
        return username.length > 0 && password.length > 0;
    }

    // Notably here the button is disabled if the form isn't validated
    // TODO: Forgot password and Sign up
    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <img height="175" width="175" src={logo} alt="CYE Logo" />
                <br />
                <form className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        className={classes.form}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        className={classes.form}
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        margin="normal"
                        fullWidth
                        className={classes.form}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!validateForm()}
                    >
                        Sign In
                    </Button>
                    {!isValid && (
                        <FormControl className={classes.error} error>
                            <FormHelperText>
                                Incorrect username or password.
                            </FormHelperText>
                        </FormControl>
                    )}
                </form>
            </div>
        </Container>
    );
}
