import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    IconButton,
    Typography,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SurveyContents from './SurveyContents';
import Alert from './Alert/Alert';
import { endpoints } from './../Constants/Config';
import { universalPost } from '../Functions/UniversalPost/UniversalPost';
import SubmitModal from './SubmitModal';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonGroupDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        padding: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

// Handles the modal transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// The container for the entire risk assesment form
const SurveyComponentModal = (props) => {
    const classes = useStyles();
    const {
        formOpen,
        setFormOpen,
        participantName,
        questions,
        surveyID,
    } = props;

    const createResponsesObject = () => {
        let responses = [];
        for (let i = 0; i < questions.length; i++) {
            switch (questions[i].question_type) {
                case 'slider':
                    responses.push({
                        organization_id: Number(
                            sessionStorage.getItem('organizationID')
                        ),
                        survey_id: surveyID,
                        question_id: questions[i].id,
                        response_type: 'slider',
                        slider_value: null,
                    });
                    break;
                case 'open_response':
                    responses.push({
                        organization_id: Number(
                            sessionStorage.getItem('organizationID')
                        ),
                        survey_id: surveyID,
                        question_id: questions[i].id,
                        response_type: 'open_response',
                        open_response_value: null,
                    });
                    break;
                default:
                    break;
            }
        }
        return responses;
    };

    const [responses, setResponses] = React.useState(createResponsesObject());
    const [
        createResponsesResponse,
        setCreateResponsesResponse,
    ] = React.useState({
        data: null,
        loading: false,
        error: null,
    });
    const [alertStatus, setAlertStatus] = React.useState({
        isOpen: false,
        isError: false,
        text: '',
    });

    const [submitModalOpen, setSubmitModalOpen] = React.useState(false);
    const handleSubmitSurvey = () => {
        //console.log(responses);
        universalPost(
            setCreateResponsesResponse,
            endpoints.surveyResponse,
            responses,
            (errorMessage) => {
                setAlertStatus({
                    isOpen: true,
                    isError: true,
                    text: 'Response was not saved. ' + errorMessage,
                });
            },
            () => {
                setAlertStatus({
                    isOpen: true,
                    isError: false,
                    text: 'Response saved successfully!',
                });
                setFormOpen(false);
            }
        );
    };

    return (
        <div className={classes.root}>
            <Dialog
                //onClose={() => setFormOpen(false)}
                open={formOpen}
                fullWidth
                maxWidth="lg"
                TransitionComponent={Transition}
            >
                <DialogTitle onClose={() => setFormOpen(false)}>
                    <Typography>
                        <strong>{participantName}</strong> Youth Outcomes Form
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => setFormOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography>
                        Please read the following statements about the youth or
                        student you are reviewing. Rate each item based on how
                        much you agree or disagree with the statement. If you
                        absolutely agree, slide the slider all the way to the
                        right. If you absolutely disagree, slide to the left.
                        You can rate each response anywhere between Disagree and
                        Agree.
                    </Typography>
                    <br />
                    <SurveyContents
                        questions={questions}
                        setResponses={setResponses}
                        responses={responses}
                    />
                    <Alert
                        alertStatus={alertStatus}
                        setAlertStatus={setAlertStatus}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setSubmitModalOpen(true);
                        }}
                    >
                        Submit Survey
                    </Button>
                </DialogActions>
            </Dialog>
            <SubmitModal
                setModalOpen={setSubmitModalOpen}
                modalOpen={submitModalOpen}
                participantName={participantName}
                handleSendResponses={handleSubmitSurvey}
            />
        </div>
    );
};

export default SurveyComponentModal;
