import React, { useState } from 'react';
import {
    FormHelperText,
    FormControl,
    Button,
    CssBaseline,
    TextField,
    Link,
    Grid,
    Box,
    Typography,
    makeStyles,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { universalPost } from '../Functions/UniversalPost/UniversalPost';
import { endpoints } from '../Constants/Config';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    error: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUp() {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [orgID, setOrgID] = useState('');
    const [open, setOpen] = useState(false);
    const [createStaffResponse, setCreateStaffResponse] = useState({
        data: null,
        loading: false,
        error: null,
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const signUpResponse = await Auth.signUp({
                username,
                password,
                attributes: {
                    'custom:role': role,
                    'custom:organization_id': orgID,
                },
            });
            console.log(signUpResponse);
            universalPost(
                setCreateStaffResponse,
                endpoints.createStaff,
                {
                    first_name: username,
                    last_name: '',
                    organization_id: orgID,
                    role: role,
                },
                (err) => {
                    alert('Staff not created in database');
                },
                (resp) => {
                    console.log('Staff Created', resp);
                }
            );
        } catch (error) {
            console.log(error);
            alert(error.message);
        }
    };

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            const press = handleSubmit;
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Create a user
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="rolel"
                        label="Role"
                        name="role"
                        onChange={(e) => setRole(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="orgID"
                        label="Organization ID"
                        id="orgnID"
                        onChange={(e) => setOrgID(e.target.value)}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                    >
                        Create Account
                    </Button>
                </form>
            </div>
        </Container>
    );
}
