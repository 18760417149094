import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    IconButton,
    Typography,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    ButtonGroup,
    Slide,
    Collapse,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert as MUIAlert, AlertTitle } from '@material-ui/lab';
import FormPage from '../FormPage/FormPage';
import questions from '../../Constants/Questions';
import questionGroups from '../../Constants/QuestionGroups';
import PropTypes from 'prop-types';
import universalFetch from '../../Functions/UniversalFetch/UniversalFetch';
import { universalPost } from '../../Functions/UniversalPost/UniversalPost';
import { endpoints } from '../../Constants/Config';
import SubmitModal from '../SubmitRiskModal';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonGroupDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        padding: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

// Handles the modal transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// The container for the entire risk assesment form
const FormPageContainer = (props) => {
    const classes = useStyles();
    const { formOpen, setFormOpen, firstName, lastName, id } = props;

    // Returns the number of total questions in the survey which is used for the progress bar
    const getQuestionCount = () => {
        let count = 0;
        questionGroups.forEach(
            (question) => (count += questions[question].length)
        );
        return count;
    };

    const questionCount = getQuestionCount();

    const [titleIndex, setTitleIndex] = React.useState(0); // The index of the current header / question group
    const [isComplete, setIsComplete] = React.useState(false); // If the survey is complete or not
    const [openModal, setModalOpen] = React.useState(false); // Stores the state of the modal
    const [completion, setCompletion] = React.useState(0); // Stores % of form that is completed
    const [submitModalOpen, setSubmitModalOpen] = React.useState(false);

    // Used when the staff select 'continue' in the modal and leave unresolved questions
    const handleModalClose = () => {
        setModalOpen(false);
        handleNextPage();
    };

    // Stores all of the answers for the quiz
    const [riskValues, setRiskValues] = React.useState({
        'School Factors': Array(questions['School Factors'].length).fill(null),
        'Home Factors': Array(questions['Home Factors'].length).fill(null),
        'Gang / Youth Violence Factors': Array(
            questions['Gang / Youth Violence Factors'].length
        ).fill(null),
        'Peers and Other': Array(questions['Peers and Other'].length).fill(
            null
        ),
    });

    const [addRiskResponse, setAddRiskResponse] = React.useState({
        data: null,
        loading: true,
        error: null,
    });

    const [getRiskQuestions, setGetRiskQuestions] = React.useState({
        data: null,
        error: null,
        loading: false,
    });

    // Updates an answer given the title of the question group, the index of the question, and the new value
    const handleUpdateRiskValues = (index, title, value) => {
        const dict = riskValues;
        if (dict[title][index] === null && value !== null) {
            setCompletion(completion + 1 / questionCount);
        } else if (dict[title][index] !== null && value === null) {
            setCompletion(completion - 1 / questionCount);
        }
        dict[title][index] = value;
        setRiskValues(dict);
    };

    // Returns all of the answers for a question group
    const getRiskValues = (title) => {
        return riskValues[title];
    };

    // This is a really wierd function. Due to time constraints, we take the ID of the first question for
    // the given org and offset it by that ID when adding responses. This works because I create the questions
    // for an ORG in the same order that I add the responses here, so the order will be the same.
    //
    // To improve, this whole page should be using the questions from the server, rather than the ones here,
    // but that would take time to write.
    const createRiskResponses = (idOffset) => {
        let body = [];
        let index = idOffset;
        riskValues['School Factors'].map((question) => {
            question &&
                body.push({
                    participant_id: id,
                    reviewer_id: sessionStorage.getItem('reviewerID'),
                    question_id: index,
                    risk_rating: question,
                });
            index = index + 1;
        });
        riskValues['Home Factors'].map((question) => {
            question &&
                body.push({
                    participant_id: id,
                    reviewer_id: sessionStorage.getItem('reviewerID'),
                    question_id: index,
                    risk_rating: question,
                });
            index = index + 1;
        });
        riskValues['Gang / Youth Violence Factors'].map((question) => {
            question &&
                body.push({
                    participant_id: id,
                    reviewer_id: sessionStorage.getItem('reviewerID'),
                    question_id: index,
                    risk_rating: question,
                });
            index = index + 1;
        });
        riskValues['Peers and Other'].map((question) => {
            question &&
                body.push({
                    participant_id: id,
                    reviewer_id: sessionStorage.getItem('reviewerID'),
                    question_id: index,
                    risk_rating: question,
                });
            index = index + 1;
        });
        return body;
    };

    // Navigates to the next form page
    const handleNextPage = () => {
        setModalOpen(false);
        if (titleIndex === questionGroups.length - 1) {
            setSubmitModalOpen(true);
        } else {
            setTitleIndex(titleIndex + 1);
        }
    };

    const handleCompleteSurvey = () => {
        universalFetch(
            setGetRiskQuestions,
            `/organization/${sessionStorage.getItem(
                'organizationID'
            )}/risk_assessment/questions/`,
            () => {},
            (resp) => {
                //console.log(createRiskResponses(resp[0].id))
                universalPost(
                    setAddRiskResponse,
                    endpoints.riskResponses,
                    createRiskResponses(resp[0].id), // the starting ID for this ORG
                    () => {
                        alert('failed to add responses');
                    },
                    () => {
                        console.log('Added questions successfully');
                        setIsComplete(true);
                        window.location.reload();
                    }
                );
            }
        );
    };

    // Attempts to go to the next page. Opens the modal if there are null responses
    const handleNextPageAttempt = () => {
        const count = questions[questionGroups[titleIndex]].length;
        for (let i = 0; i < count; i++) {
            if (riskValues[questionGroups[titleIndex]][i] === null) {
                setModalOpen(true);
                return;
            }
        }
        handleNextPage();
    };

    // Navigates to the previous page
    const handlePreviousPage = () => {
        setModalOpen(false);
        if (titleIndex === 0) {
            setTitleIndex(0);
        } else {
            setTitleIndex(titleIndex - 1);
        }
    };

    // Displays the completion page if the form is completed
    if (isComplete) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Dialog
                onClose={() => setFormOpen(false)}
                open={formOpen}
                fullWidth
                maxWidth="lg"
                TransitionComponent={Transition}
            >
                <DialogTitle onClose={() => setFormOpen(false)}>
                    <Typography>
                        <strong>
                            {firstName} {lastName}
                        </strong>{' '}
                        Risk Assesment Form - {Math.round(completion * 100)}%
                        Complete
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => setFormOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <FormPage
                        questions={questions[questionGroups[titleIndex]]}
                        getValues={getRiskValues}
                        riskValues={riskValues}
                        handleUpdateRiskValues={handleUpdateRiskValues}
                        title={questionGroups[titleIndex]}
                    />
                    <div className={classes.buttonGroupDiv}>
                        <ButtonGroup variant="contained" color="primary">
                            <Button
                                onClick={handlePreviousPage}
                                color="primary"
                            >
                                Previous Page
                            </Button>
                            {titleIndex < questionGroups.length - 1 && (
                                <Button
                                    onClick={handleNextPageAttempt}
                                    color="primary"
                                >
                                    Next Page
                                </Button>
                            )}
                            {titleIndex === questionGroups.length - 1 && (
                                <Button
                                    onClick={handleNextPageAttempt}
                                    color="primary"
                                >
                                    Submit Form
                                </Button>
                            )}
                        </ButtonGroup>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Collapse in={openModal}>
                        <MUIAlert
                            aria-label="Warning: not all fields were filled out"
                            severity="warning"
                            action={
                                <>
                                    <Button
                                        aria-label="Back to Form"
                                        color="inherit"
                                        onClick={() => setModalOpen(false)}
                                    >
                                        Back to Form
                                    </Button>
                                    <Button
                                        aria-label="Continue to next page"
                                        color="inherit"
                                        onClick={handleModalClose}
                                    >
                                        Continue
                                    </Button>
                                </>
                            }
                        >
                            <AlertTitle>Warning</AlertTitle>
                            There are areas of the form that have not recieved a
                            value. Remember that you can scoll over the help
                            icons if you do not understand a question. Are you
                            sure you want to continue?
                        </MUIAlert>
                    </Collapse>
                </DialogActions>
            </Dialog>
            <SubmitModal
                setModalOpen={setSubmitModalOpen}
                modalOpen={submitModalOpen}
                participantName={`${firstName} ${lastName}`}
                handleSendResponses={handleCompleteSurvey}
            />
        </div>
    );
};

FormPageContainer.propTypes = {
    formOpen: PropTypes.bool.isRequired,
    setFormOpen: PropTypes.func.isRequired,
    lastName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
};

export default FormPageContainer;
