import { Typography } from '@material-ui/core';
import React from 'react';
import SliderQuestion from './SliderQuestion';
import OpenResponseQuestion from './OpenResponseQuestion';

const QuestionComponent = (props) => {
    const { question, index, responses, setResponses } = props;
    let questionComponent = null;
    switch (question.question_type) {
        case 'slider':
            questionComponent = (
                <SliderQuestion
                    question={question}
                    index={index}
                    responses={responses}
                    setResponses={setResponses}
                />
            );
            break;
        case 'open_response':
            questionComponent = (
                <OpenResponseQuestion
                    question={question}
                    index={index}
                    responses={responses}
                    setResponses={setResponses}
                />
            );
            break;
        default:
            break;
    }
    return (
        <div>
            <Typography align="center">{question.content}</Typography>
            {questionComponent}
        </div>
    );
};

export default QuestionComponent;
