import React, { useState } from 'react';
import {
    Button,
    CssBaseline,
    TextField,
    Typography,
    makeStyles,
    Container,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { universalPost } from '../Functions/UniversalPost/UniversalPost';
import { endpoints } from '../Constants/Config';
import credentials from '../Constants/Credentials';
import axios from 'axios';
import { baseURL } from '../Constants/Config';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    error: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

// Signs up multiple users. Lots commented out that will be possibly needed.
// For now, I have found the best approach to be click the button for each individual
// user, to ensure the server doesnt get DDOSed. In the future, a backend script will be written
// and this file can be deleted. Note that this will fail without creating the credentials file, which is
// not stored in git for security reasons
export default function SignUp() {
    const classes = useStyles();
    const [index, setIndex] = React.useState(0);
    const handleCreateUser = async (user) => {
        /*try {
            const signUpResponse = await Auth.signUp({
                username: user.username,
                password: user.password,
                attributes: {
                    'custom:role': user.role,
                    'custom:organization_id': String(user.organization_id),
                },
            });
            console.log(`Signed up ${user.username} from cognito`);
            universalPost(
                ((data)=> {}),
                endpoints.createStaff,
                {
                    first_name: user.username,
                    last_name: '',
                    organization_id: user.organization_id,
                    role: user.role,
                },
                (err) => {
                    alert('Staff not created in database');
                },
                (resp) => {
                    console.log(`Added staff ${user.username} to db`);
                }
            );
        } catch (error) {
            console.log(error);
            alert(error.message);
        }*/
        universalPost(
            (data) => {},
            endpoints.createStaff,
            {
                first_name: credentials[index].username,
                last_name: '',
                organization_id: credentials[index].organization_id,
                role: credentials[index].role,
            },
            (err) => {
                alert('Staff not created in database');
            },
            (resp) => {
                console.log(`Added staff ${credentials[index].username} to db`);
            }
        );
    };

    const handleSubmit = async (event) => {
        /* let user = await axios
                .post(
                    `${baseURL}${endpoints.createStaff}`, 
                    {headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },},
                    {
                        first_name: credentials[index].username,
                        last_name: '',
                        organization_id: credentials[index].organization_id,
                        role: credentials[index].role,
                    },
                )
                .then((resp) => {
                    console.log(`Added staff ${credentials[index].username} to db`)
                    setIndex(index)
                })
                .catch((error) => {
                    console.error(error.message)
                })*/
        universalPost(
            (data) => {},
            endpoints.createStaff,
            {
                first_name: credentials[index].username,
                last_name: '',
                organization_id: credentials[index].organization_id,
                role: credentials[index].role,
            },
            (err) => {
                alert('Staff not created in database');
            },
            (resp) => {
                console.log(`Added staff ${credentials[index].username} to db`);
                setIndex(index + 1);
            }
        );
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Create a user
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                    >
                        Add all accounts
                    </Button>
                </form>
            </div>
        </Container>
    );
}
