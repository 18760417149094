import axios from 'axios';
import { baseURL } from '../../Constants/Config';

const options = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
};

// Universal fetch request using axios
export default function universalFetch(
    setResponse,
    endpoint,
    onError,
    onSuccess,
    additional_options = {}
) {
    console.log('Fetch started');

    for (const [key, value] of Object.entries(additional_options)) {
        options[key] = value;
    }

    setResponse({
        data: null,
        loading: true,
        error: null,
    });
    axios
        .get(`${baseURL}${endpoint}`, options)
        .then((resp) => {
            console.log('Response received');
            console.log(resp);
            setResponse({
                data: resp.data,
                loading: false,
                error: null,
            });
            onSuccess && onSuccess(resp.data);
        })
        .catch((err) => {
            console.error(`Fetch failed with error ${err.message}`);
            setResponse({
                data: null,
                loading: false,
                error: err.message,
            });
            onError && onError(err.message);
        });
}
