import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import TypographyWithHelp from '../TypographyWithHelp/TypographyWithHelp';
import PropTypes from 'prop-types';

// Renders a question row for the risk assesment form

const RadioButtonsGroup = (props) => {
    const {
        question,
        index,
        title,
        handleUpdateRiskValues,
        riskValues,
    } = props;
    const [value, setValue] = React.useState(riskValues[title][index]);

    React.useEffect(() => {
        setValue(riskValues[title][index]);
    }, [title]);

    const handleClick = (newValue) => {
        if (value === newValue) {
            handleUpdateRiskValues(index, title, null);
            setValue(riskValues[title][index]);
        } else {
            handleUpdateRiskValues(index, title, newValue);
            setValue(riskValues[title][index]);
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item md={1} />
            <Grid item xs={12} md={4}>
                <TypographyWithHelp text={question} />
            </Grid>
            <Grid item>
                <Button
                    variant={value === 0 ? 'contained' : 'outlined'}
                    onClick={() => handleClick(0)}
                    color={value === 0 ? 'primary' : 'default'}
                >
                    <Typography>No Risk</Typography>
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant={value === 1 ? 'contained' : 'outlined'}
                    onClick={() => handleClick(1)}
                    color={value === 1 ? 'primary' : 'default'}
                >
                    <Typography>Low Risk</Typography>
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant={value === 2 ? 'contained' : 'outlined'}
                    onClick={() => handleClick(2)}
                    color={value === 2 ? 'primary' : 'default'}
                >
                    <Typography>High Risk</Typography>
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant={value === 3 ? 'contained' : 'outlined'}
                    onClick={() => handleClick(3)}
                    color={value === 3 ? 'primary' : 'default'}
                >
                    <Typography>Proven Risk</Typography>
                </Button>
            </Grid>
        </Grid>
    );
};

RadioButtonsGroup.propTypes = {
    question: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    handleUpdateRiskValues: PropTypes.func.isRequired,
    riskValues: PropTypes.object.isRequired,
};

export default RadioButtonsGroup;
