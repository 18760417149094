import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    IconButton,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
    Button,
    DialogActions,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import OptionalDateOfBirth from '../OptionalDateOfBirth/OptionalDateOfBirth';
import {
    raceOptions,
    ethnicityOptions,
    genderOptions,
    disabilityOptions,
    gradeOptions,
    gangOptions,
    mentalHealthOptions,
} from '../../Constants/ParticipantInfoDropDownText';
import TypographyWithHelp from '../TypographyWithHelp/TypographyWithHelp';
import EditableParticipant from '../EditableParticipant/EditableParticipant';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    input: {
        paddingBottom: theme.spacing(4),
    },
}));

// Handles the modal transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ParticipantView = (props) => {
    const classes = useStyles();
    const { formOpen, setFormOpen, participant } = props;
    const [participantInfo, setParticipantInfo] = React.useState({
        ...participant,
    });
    const [editable, setEditable] = React.useState(false);

    let dialogContent;

    // This section of the code is a bit long for my liking, but once the server adds questionType to the response, this can all be generalized using .map with a switch on questionType
    dialogContent = (
        <DialogContent dividers>
            {!editable && (
                <>
                    <Typography>
                        <strong>First Name:</strong>{' '}
                        {participantInfo.first_name}
                    </Typography>
                    <Typography>
                        <strong>Last Name:</strong> {participantInfo.last_name}
                    </Typography>
                    {participantInfo.date_of_birth && (
                        <Typography>
                            <strong>Date of Birth:</strong>{' '}
                            {moment(
                                participantInfo.date_of_birth,
                                'YYYY-MM-DD'
                            ).format('MMMM Do, YYYY')}
                        </Typography>
                    )}
                    {!participantInfo.date_of_birth && participantInfo.age && (
                        <Typography>
                            <strong>Age:</strong> {participantInfo.age}
                        </Typography>
                    )}
                    <Typography>
                        <strong>Gender:</strong> {participantInfo.gender}
                    </Typography>
                    <Typography>
                        <strong>Race:</strong> {participantInfo.race}
                    </Typography>
                    <Typography>
                        <strong>Ethnicity:</strong> {participantInfo.ethnicity}
                    </Typography>
                    <Typography>
                        <strong>Address:</strong> {participantInfo.address}
                    </Typography>
                    <Typography>
                        <strong>School Name:</strong>{' '}
                        {participantInfo.school_name}
                    </Typography>
                    <Typography>
                        <strong>Grade:</strong> {participantInfo.grade}
                    </Typography>
                    <Typography>
                        <strong>Organization Name:</strong>{' '}
                        {participantInfo.organization_name}
                    </Typography>
                    <Typography>
                        <strong>Gang Involvement:</strong>{' '}
                        {participantInfo.gang_involvement ? 'Yes' : 'No'}
                    </Typography>
                    <Typography>
                        <strong>Disability:</strong>{' '}
                        {participantInfo.disability}
                    </Typography>
                    <Typography>
                        <strong>Has Mental Health Need:</strong>{' '}
                        {participantInfo.mental_health}
                    </Typography>
                    <Typography>
                        <strong>Youth Outcomes Survey Complete:</strong>{' '}
                        {participantInfo.survey &&
                        participantInfo.survey.responses.length > 0
                            ? 'True'
                            : 'False'}
                    </Typography>
                    <Typography>
                        <strong>Risk Assessment Complete:</strong>{' '}
                        {participantInfo.risk_assessment &&
                        participantInfo.risk_assessment.responses.length > 0
                            ? 'True'
                            : 'False'}
                    </Typography>
                </>
            )}
            {editable && (
                <EditableParticipant
                    participantInfo={participantInfo}
                    setParticipantInfo={setParticipantInfo}
                />
            )}
        </DialogContent>
    );

    return (
        <div className={classes.root}>
            <Dialog
                onClose={() => setFormOpen(false)}
                open={formOpen}
                fullWidth
                maxWidth="sm"
                TransitionComponent={Transition}
            >
                <DialogTitle onClose={() => setFormOpen(false)}>
                    <Typography>
                        <strong>
                            {participantInfo.first_name}{' '}
                            {participantInfo.last_name}&#39;s Information Form
                        </strong>
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => setFormOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {dialogContent}
                {/* This is commented out, but will be used if/when the server impliments a PUT for participant INFO
                    <DialogActions>
                    {!editable && (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                            onClick={() => setEditable(true)}
                        >
                            Edit
                        </Button>
                    )}
                    {editable && (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveAltIcon />}
                            onClick={() => setEditable(false)}
                        >
                            Save
                        </Button>
                    )}
                </DialogActions>*/}
            </Dialog>
        </div>
    );
};

ParticipantView.propTypes = {
    formOpen: PropTypes.bool.isRequired,
    setFormOpen: PropTypes.func.isRequired,
};

export default ParticipantView;
