import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

// A custom layer over the MUI Alert component, primarily used in the App for displaying Error/Sucess codes of networking calls

export default function CustomAlert(props) {
    const classes = useStyles();
    const { alertStatus, setAlertStatus } = props;

    return (
        <div className={classes.root}>
            <Collapse in={alertStatus.isOpen}>
                <Alert
                    severity={alertStatus.isError ? 'error' : 'success'}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertStatus({
                                    ...alertStatus,
                                    isOpen: false,
                                });
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    <AlertTitle>
                        {alertStatus.isError ? 'Error' : 'Success'}
                    </AlertTitle>
                    {alertStatus.text}
                </Alert>
            </Collapse>
        </div>
    );
}
