import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, List, ListItem, TextField } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PersonIcon from '@material-ui/icons/Person';
import ParticipantView from '../ParticipantView/ParticipantView';
import universalFetch from '../../Functions/UniversalFetch/UniversalFetch';
import Alert from '../Alert/Alert';
import { getFormattedName } from '../../Functions/getFormattedName';
import { endpoints } from '../../Constants/Config';

const useStyles = makeStyles((theme) => ({
    card: {
        width: window.innerWidth / 2,
        height: window.innerHeight * 0.7,
        overflow: 'auto',
        backgroundColor: '#fafffb',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerIcon: {
        paddingRight: theme.spacing(2),
    },
    newFormButton: {
        color: '#ccc',
    },
    searchBar: {
        width: window.innerWidth / 2,
    },
}));

// List of all participants that opens a modal showing their information
const ParticipantsPreview = () => {
    const classes = useStyles();
    const [participantFormOpen, setParticipantFormOpen] = React.useState(false);
    const [riskFormOpen, setRiskFormOpen] = React.useState(false);
    const [filteredName, setFilteredName] = React.useState('');
    const [participant, setParticipant] = React.useState(null);
    const [alertStatus, setAlertStatus] = React.useState({
        isOpen: false,
        isError: false,
        text: '',
    });
    const [participants, setParticipants] = React.useState({
        data: null,
        loading: false,
        error: null,
    });
    useEffect(() => {
        universalFetch(
            setParticipants,
            `${
                endpoints.allParticipants
            }?organization_id=${sessionStorage.getItem('organizationID')}`,
            (errorMessage) => {
                setAlertStatus({
                    isOpen: true,
                    isError: true,
                    text: 'Failed to fetch particpants. ' + errorMessage,
                });
            },
            () => {
                setAlertStatus({ ...alertStatus, isOpen: false });
            }
        );
    }, []);

    const handleFormStatus = (status) => {
        setParticipantFormOpen(status);
        if (status === false) {
            setParticipant(null);
        }
    };
    const handleOpenParticipantForm = (participant) => {
        setParticipant(participant);
        setParticipantFormOpen(true);
    };

    const handleOpenRiskForm = (participant) => {
        setParticipant(participant);
        setRiskFormOpen(true);
    };

    let header = (
        <>
            <br />
            <Typography variant="h5">Participants</Typography>
            <br />
            <TextField
                fullWidth
                className={classes.searchBar}
                variant="outlined"
                margin="normal"
                align="center"
                placeholder="Search for Participant"
                onChange={(e) => setFilteredName(e.target.value)}
            />
            <br />
        </>
    );

    // TODO: edit skeleton components to more closely mimic size of a participant accordian component
    if (participants.loading) {
        return (
            <div className={classes.root}>
                {header}
                <Card className={classes.card}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Card>
            </div>
        );
    }
    if (participants.error) {
        return (
            <div className={classes.root}>
                {header}
                <Card className={classes.card}>
                    <Alert
                        alertStatus={alertStatus}
                        setAlertStatus={setAlertStatus}
                    />
                </Card>
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                {header}
                <Card className={classes.card}>
                    <List>
                        {participants.data !== null &&
                            participants.data
                                .filter((option) =>
                                    String(
                                        getFormattedName(
                                            option.first_name,
                                            option.last_name
                                        )
                                    ).includes(filteredName.toLowerCase())
                                )
                                .map((participant) => (
                                    <ListItem
                                        key={participant.id}
                                        button
                                        onClick={() =>
                                            handleOpenParticipantForm(
                                                participant
                                            )
                                        }
                                    >
                                        <PersonIcon
                                            className={classes.headerIcon}
                                        />
                                        <Typography className={classes.heading}>
                                            {participant.first_name}{' '}
                                            {participant.last_name}
                                        </Typography>
                                    </ListItem>
                                ))}
                    </List>
                </Card>
                {/* The two current forms a particpant can have. Rendered here because they are a Dialog rather than a new page that gets loaded */}
                {participant !== null && (
                    <ParticipantView
                        participant={participant}
                        formOpen={participantFormOpen}
                        setFormOpen={handleFormStatus}
                    />
                )}
            </div>
        );
    }
};

export default ParticipantsPreview;
