import React, { useEffect } from 'react';
import { makeStyles, Container, Typography, Button } from '@material-ui/core';
import EditableParticipant from '../EditableParticipant/EditableParticipant';
import { universalPost } from '../../Functions/UniversalPost/UniversalPost';
import universalFetch from '../../Functions/UniversalFetch/UniversalFetch';
import { endpoints } from '../../Constants/Config';
import Alert from '../Alert/Alert';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2),
        align: 'center',
    },
}));

// Page that creates a participant
const CreateParticipant = () => {
    const classes = useStyles();
    const [participantInfo, setParticipantInfo] = React.useState({
        first_name: null,
        last_name: null,
        gender: null,
        date_of_birth: null,
        ethnicity: null,
        age: null,
        race: null,
        grade: null,
        school_name: null,
        address: null,
        gang_involvement: null,
        disability: null,
        mental_health: null,
        organization_id: null,
    });
    const [
        createParticipantResponse,
        setCreateParticipantResponse,
    ] = React.useState({
        data: null,
        loading: false,
        error: null,
    });
    const [alertStatus, setAlertStatus] = React.useState({
        isOpen: false,
        isError: false,
        text: '',
    });
    const [
        fetchOrganizationsStatus,
        setFetchOrganizationsStatus,
    ] = React.useState({
        data: null,
        loading: false,
        error: null,
    });

    const checkValidForm = () => {
        return (
            participantInfo.first_name &&
            participantInfo.last_name &&
            participantInfo.gender &&
            participantInfo.ethnicity &&
            participantInfo.race &&
            participantInfo.organization_id &&
            participantInfo.gang_involvement &&
            (participantInfo.date_of_birth || participantInfo.age)
        );
    };

    const handleCreateParticipant = () => {
        if (checkValidForm() === false) {
            setAlertStatus({
                isOpen: true,
                isError: true,
                text: 'Please input a value for all required fields',
            });
            return;
        }
        universalPost(
            setCreateParticipantResponse,
            endpoints.participant,
            participantInfo,
            (errorMessage) => {
                setAlertStatus({
                    isOpen: true,
                    isError: true,
                    text: 'Participant was not created. ' + errorMessage,
                });
            },
            () => {
                setAlertStatus({
                    isOpen: true,
                    isError: false,
                    text: 'Participant created successfully!',
                });
            }
        );
    };

    useEffect(() => {
        universalFetch(
            setFetchOrganizationsStatus,
            endpoints.allOrganizations,
            () => {},
            () => {}
        );
    }, []);

    return (
        <Container component="main" maxWidth="sm">
            <div className={classes.paper}>
                <form className={classes.form}>
                    <Typography align="center">
                        Create New Participant
                    </Typography>
                    <br />
                    <EditableParticipant
                        organizations={fetchOrganizationsStatus.data}
                        participantInfo={participantInfo}
                        setParticipantInfo={setParticipantInfo}
                    />
                </form>
                <br />
                <Alert
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                />
                <br />
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateParticipant}
                >
                    Create Participant
                </Button>
                <br />
                <br />
            </div>
        </Container>
    );
};

export default CreateParticipant;
