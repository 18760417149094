import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
} from '@material-ui/core';

const ConfirmModal = (props) => {
    const {
        modalOpen,
        setModalOpen,
        handleCreateSurvey,
        participantName,
    } = props;
    const handleContinue = () => {
        setModalOpen(false);
        handleCreateSurvey();
    };
    return (
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
            <DialogTitle>{'Are you sure?'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    By clicking continue, you acknowledge that you will be
                    filling out the risk assessment for <b>{participantName}</b>{' '}
                    and that once you start it must be completed. The risk
                    assessment will not be able to be edited after you complete
                    it. Are you sure you want to continue?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setModalOpen(false)}>
                    Go Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleContinue}
                >
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal;
