import React from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    makeStyles,
    Button,
} from '@material-ui/core';
import { universalPost } from '../Functions/UniversalPost/UniversalPost';
import Alert from './Alert/Alert';
import { endpoints } from './../Constants/Config';

const useStyles = makeStyles((theme) => ({
    input: {
        paddingBottom: theme.spacing(4),
    },
    root: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

// Adds questions to an org
const AddSurveyQuestions = () => {
    const classes = useStyles;
    const [orgID, setOrgID] = React.useState(null);
    const [questionType, setQuestionType] = React.useState('slider');
    const [question, setQuestion] = React.useState(null);
    const [addQuestionResponse, setAddQuestionResponse] = React.useState({
        data: null,
        loading: true,
        error: null,
    });
    const [alertStatus, setAlertStatus] = React.useState({
        isOpen: false,
        isError: false,
        text: '',
    });

    const handleAddQuestion = () => {
        universalPost(
            setAddQuestionResponse,
            endpoints.surveyQuestion,
            {
                organization_id: orgID,
                content: question,
                question_type: questionType,
            },
            (errorMessage) => {
                setAlertStatus({
                    isOpen: true,
                    isError: true,
                    text: 'Question was not added. ' + errorMessage,
                });
            },
            () => {
                setAlertStatus({
                    isOpen: true,
                    isError: false,
                    text: 'Question added successfully!',
                });
            }
        );
    };
    return (
        <div className={classes.root}>
            <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                label="Organization ID"
                onChange={(e) => setOrgID(e.target.value)}
            />
            <br />
            <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                label="Question Content"
                onChange={(e) => setQuestion(e.target.value)}
            />
            <br />
            <FormControl fullWidth className={classes.input}>
                <InputLabel>Question Type</InputLabel>
                <Select
                    value={questionType}
                    onChange={(e) => setQuestionType(e.target.value)}
                >
                    {['slider', 'open_response'].map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br />
            <Alert alertStatus={alertStatus} setAlertStatus={setAlertStatus} />
            <br />
            <Button onClick={handleAddQuestion}>Add Question</Button>
        </div>
    );
};

export default AddSurveyQuestions;
