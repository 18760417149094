import React from 'react';
import {
    Grid,
    FormControlLabel,
    Checkbox,
    makeStyles,
    TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TypographyWithHelp from '../TypographyWithHelp/TypographyWithHelp';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    input: {
        paddingBottom: theme.spacing(4),
    },
}));

// The component used in the participants information that displays a date picker or a textbox for age, depeneding on if their date of birth is known or not.
// Abstracted out of ParticipantView.js to reduce code complexity
const OptionalDateOfBirth = (props) => {
    const classes = useStyles();
    const {
        checkboxState,
        setCheckboxState,
        participantInfo,
        setParticipantInfo,
    } = props;
    return (
        <Grid container>
            <Grid item xs={4}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={checkboxState}
                            onChange={() => setCheckboxState(!checkboxState)}
                        />
                    }
                    label={
                        <TypographyWithHelp
                            variant="caption"
                            text="DOB Known?"
                            helperText="If the participant's date of birth is not known, please input their age in years"
                        />
                    }
                    labelPlacement="top"
                />
            </Grid>
            <Grid item xs={8}>
                {checkboxState && (
                    <KeyboardDatePicker
                        className={classes.input}
                        disableToolbar
                        fullWidth
                        required
                        variant="inline"
                        format="yyyy-MM-DD"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date of Birth"
                        value={participantInfo.date_of_birth}
                        onChange={(v) =>
                            setParticipantInfo({
                                ...participantInfo,
                                date_of_birth: v
                                    ? v.format('YYYY-MM-DD')
                                    : null,
                            })
                        }
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                )}
                {!checkboxState && (
                    <TextField
                        fullWidth
                        label="Age"
                        type="number"
                        required
                        className={classes.input}
                        value={participantInfo.age}
                        onChange={(e) =>
                            setParticipantInfo({
                                ...participantInfo,
                                age: Number(e.target.value),
                            })
                        }
                    />
                )}
            </Grid>
        </Grid>
    );
};

OptionalDateOfBirth.propTypes = {
    checkboxState: PropTypes.bool.isRequired,
    setCheckboxState: PropTypes.func.isRequired,
    participantInfo: PropTypes.object.isRequired,
    setParticipantInfo: PropTypes.func.isRequired,
};

export default OptionalDateOfBirth;
