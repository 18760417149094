export const isDev =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const baseURL = isDev
    ? 'http://ec2-3-139-211-10.us-east-2.compute.amazonaws.com'
    : 'https://prod.shannon-survey.com';

export const endpoints = {
    allParticipants: '/participants/',
    participant: '/participant/',
    organization: '/organization/',
    allOrganizations: '/organizations/',
    survey: '/survey/',
    surveyResponse: '/survey/responses/',
    surveyQuestion: '/survey/question/',
    riskQuestions: '/risk_assessment/questions/',
    riskResponses: '/risk_assessment/responses/',
    createStaff: '/staff/',
    createRisk: '/risk_assessment/',
    surveyReport: '/survey/report/',
};
