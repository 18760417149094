import React from 'react';
import { universalPost } from '../../Functions/UniversalPost/UniversalPost';
import universalFetch from '../../Functions/UniversalFetch/UniversalFetch';
import { endpoints } from '../../Constants/Config';
import { TextField, Button, Typography, makeStyles } from '@material-ui/core';
import Alert from '../Alert/Alert';
import questions from '../../Constants/Questions';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

// Page that handles creating an organization and adding risk questions to that organization
//
// This page should NOT be used by anyone other than us (and cannot be as it is set to admin only)
//
// If we wanted to make it director accessible, we would want to tie adding the questions into
// creating the organizaiton, so a director doesnt accidentally add the questions multiple times,
// as well as connecting the get all organizations endpoint to make it easier visually
const CreateOrganization = () => {
    const classes = useStyles;
    const [organization, setOrganization] = React.useState('');
    const [orgID, setOrgID] = React.useState('');
    const [fetchOrgResponse, setFetchOrgResponse] = React.useState({
        data: null,
        loading: true,
        error: null,
    });
    const [createOrgResponse, setCreateOrgResponse] = React.useState({
        data: null,
        loading: true,
        error: null,
    });
    const [addQuestionsResponse, setAddQuestionsResponse] = React.useState({
        data: null,
        loading: true,
        error: null,
    });
    const [alertStatus, setAlertStatus] = React.useState({
        isOpen: false,
        isError: false,
        text: '',
    });

    // Creates the body of questions to be send to the server
    const createQuestionsBody = () => {
        let body = [];
        questions['School Factors'].map((question) => {
            body.push({
                organization_id: Number(orgID),
                category: 'School Factors',
                content: question,
            });
        });
        questions['Home Factors'].map((question) => {
            body.push({
                organization_id: Number(orgID),
                category: 'Home Factors',
                content: question,
            });
        });
        questions['Gang / Youth Violence Factors'].map((question) => {
            body.push({
                organization_id: Number(orgID),
                category: 'Gang/Youth Violence Factors',
                content: question,
            });
        });
        questions['Peers and Other'].map((question) => {
            body.push({
                organization_id: Number(orgID),
                category: 'Peers and Others',
                content: question,
            });
        });
        return body;
    };

    const handleSubmit = () => {
        universalPost(
            setCreateOrgResponse,
            endpoints.organization,
            { name: organization },
            (errorMessage) => {
                setAlertStatus({
                    isOpen: true,
                    isError: true,
                    text: 'Organization was not created. ' + errorMessage,
                });
            },
            (data) => {
                setAlertStatus({
                    isOpen: true,
                    isError: false,
                    text: 'Organization created successfully!',
                });
                setOrgID(data.id);
                //console.log(data.id);
            }
        );
    };

    const handleAddQuestionsToOrg = () => {
        //console.log(createQuestionsBody());
        universalPost(
            setAddQuestionsResponse,
            endpoints.riskQuestions,
            createQuestionsBody(),
            (errorMessage) => {
                setAlertStatus({
                    isOpen: true,
                    isError: true,
                    text: 'Questions were not added. ' + errorMessage,
                });
            },
            () => {
                setAlertStatus({
                    isOpen: true,
                    isError: false,
                    text: 'Questions added successfully!',
                });
            }
        );
    };

    const handleGET = () => {
        universalFetch(
            setFetchOrgResponse,
            endpoints.organization + orgID,
            (errorMessage) => {
                setAlertStatus({
                    isOpen: true,
                    isError: true,
                    text: 'Organization not found. ' + errorMessage,
                });
            },
            () => {}
        );
    };

    return (
        <div className={classes.root}>
            <TextField
                variant="outlined"
                margin="normal"
                label="New Organization Name"
                onChange={(e) => setOrganization(e.target.value)}
            />
            <br />
            <br />
            <Button
                onClick={handleSubmit}
                margin="normal"
                variant="contained"
                color="primary"
            >
                Create Organization
            </Button>
            <br />
            <br />
            <TextField
                variant="outlined"
                margin="normal"
                label="Organization ID"
                value={orgID}
                onChange={(e) => setOrgID(e.target.value)}
            />
            <br />
            <br />
            <Button
                onClick={handleGET}
                margin="normal"
                variant="contained"
                color="primary"
            >
                Get Organization
            </Button>
            <br />
            <br />
            <Button
                onClick={handleAddQuestionsToOrg}
                margin="normal"
                variant="contained"
                color="primary"
            >
                Add Questions to Organization
            </Button>
            <br />
            <br />
            {fetchOrgResponse.data && (
                <Typography>
                    <b>Organization Name: </b>
                    {fetchOrgResponse.data.name}
                </Typography>
            )}
            <br />
            <br />
            <Alert alertStatus={alertStatus} setAlertStatus={setAlertStatus} />
        </div>
    );
};

export default CreateOrganization;
