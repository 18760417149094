import React from 'react';
import {
    withStyles,
    Grid,
    Typography,
    Slider,
    makeStyles,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import '../Constants/tableView.css';
import PropTypes from 'prop-types';

const sliderScale = isMobile ? 1 : 2;

const useStyles = makeStyles((theme) => ({
    slider: {
        top: isMobile ? -theme.spacing(1) : -theme.spacing(0.5),
    },
}));

const PrettoSlider = withStyles({
    root: {
        height: 4 * sliderScale,
    },
    thumb: {
        height: 12 * sliderScale,
        width: 12 * sliderScale,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -4 * sliderScale,
        marginLeft: -6 * sliderScale,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 4 * sliderScale,
        borderRadius: 2 * sliderScale,
    },
    rail: {
        height: 4 * sliderScale,
        borderRadius: 2 * sliderScale,
    },
})(Slider);

const SliderQuestion = (props) => {
    const { question, index, responses, setResponses } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        const r = responses;
        r[index] = { ...responses[index], slider_value: newValue };
        setResponses(r);
    };

    return (
        <div className="Row">
            <Grid container spacing={2}>
                <Grid item>
                    <Typography>Disagree</Typography>
                </Grid>
                <Grid item xs>
                    <PrettoSlider
                        value={value}
                        onChange={handleChange}
                        aria-labelledby="continuous-slider"
                        className={classes.slider}
                    />
                </Grid>
                <Grid item>
                    <Typography>Agree</Typography>
                </Grid>
            </Grid>
        </div>
    );
};

SliderQuestion.propTypes = {
    question: PropTypes.object.isRequired,
};

export default SliderQuestion;
