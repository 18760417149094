import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import roles from '../Constants/Roles';

// A route that redirects to login if the user is not authenticated

export default function AdminRoute({ component: C, appProps, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                sessionStorage.getItem('token') !== null &&
                sessionStorage.getItem('role') === roles.ADMIN ? (
                    <C {...props} {...appProps} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
}

AdminRoute.propTypes = {
    component: PropTypes.func.isRequired,
    appProps: PropTypes.object,
};
