import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import AdminRoute from './AdminRoute';
import Home from '../Components/Home/Home';
import CreateParticipant from '../Components/CreateParticipant/CreateParticipant';
import ParticipantsPreview from '../Components/ParticipantsPreview/ParticipantsPreview';
import YouthOutcomes from '../Components/YouthOutcomes/YouthOutcomes';
import Login from '../Components/Login/Login';
import SignUp from '../Components/SignUp';
import RiskList from '../Components/RiskList/RiskList';
import CreateOrganization from '../Components/CreateOrganization/CreateOrganization';
import AddSurveyQuestions from '../Components/AddSurveyQuestions';
import GenerateSurveyReport from '../Components/GenerateSurveyReport/GenerateSurveyReport';
import MultiUserSignUp from '../Components/MultiUserSignUp';
// Handles all the routing in the app

export default function Routes() {
    return (
        <Switch>
            <Redirect exact from="/" to="/home" />
            <AuthenticatedRoute path="/home" exact component={Home} />
            <AdminRoute
                path="/create-participant"
                exact
                component={CreateParticipant}
            />

            <AdminRoute
                path="/create-organization"
                exact
                component={CreateOrganization}
            />
            <AdminRoute
                path="/add-questions"
                exact
                component={AddSurveyQuestions}
            />
            <AdminRoute
                path="/generate-survey-report"
                exact
                component={GenerateSurveyReport}
            />
            {/*<AdminRoute
                path="/multi-signup"
                exact
                component={MultiUserSignUp}
            />*/}
            <AdminRoute path="/add-user" exact component={SignUp} />
            <AuthenticatedRoute
                path="/risk-assesments"
                exact
                component={RiskList}
            />
            <AuthenticatedRoute
                path="/youth-outcomes"
                exact
                component={YouthOutcomes}
            />
            <AuthenticatedRoute
                path="/participants"
                exact
                component={ParticipantsPreview}
            />
            <UnauthenticatedRoute path="/login" exact component={Login} />
        </Switch>
    );
}
