import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// A route that redirects the user to the home page if they go to a page that requires the user be unauthenticated

export default function UnauthenticatedRoute({
    component: C,
    appProps,
    ...rest
}) {
    return (
        <Route
            {...rest}
            render={(props) =>
                sessionStorage.getItem('token') === null ? (
                    <C {...props} {...appProps} />
                ) : (
                    <Redirect to="/home" />
                )
            }
        />
    );
}

UnauthenticatedRoute.propTypes = {
    component: PropTypes.func.isRequired,
    appProps: PropTypes.object,
};
