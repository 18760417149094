import React from 'react';
import { Typography, IconButton, Grid, Popover } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Displays a  typography component with a yellow help icon that has a popover attached to it
// TODO: add a media prop that determines what is shown (specific text, a movie, etc)

const useStyles = makeStyles((theme) => ({
    helpIcon: {
        fontSize: 16,
        // color: "#EAB225"
    },
    helpButton: {
        width: 22,
        height: 22,
        padding: 0,
    },
    text: {
        paddingRight: theme.spacing(1),
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

const TypographyWithHelp = (props) => {
    const { text, variant, helperText } = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <Grid container>
            <Typography variant={variant || 'body1'} className={classes.text}>
                {text}
            </Typography>
            <IconButton
                className={classes.helpButton}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <HelpIcon className={classes.helpIcon} />
            </IconButton>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>{helperText}</Typography>
            </Popover>
        </Grid>
    );
};

TypographyWithHelp.propTypes = {
    text: PropTypes.string.isRequired,
    variant: PropTypes.string,
    helperText: PropTypes.string,
};

export default TypographyWithHelp;
