import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Avatar, Button } from '@material-ui/core';
import riskImage from 'public/images/risk_assesment.png';
import surveyImage from 'public/images/youth_outcomes.png';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fafffb',
    },
    avatar: {
        width: 400,
        height: 400,
        border: `10px solid ${theme.palette.primary.main}`,
    },
    grid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

// The home page

const Home = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h5">
                Welcome {sessionStorage.getItem('username')}
            </Typography>
            <br />
            <Grid container spacing={4}>
                <Grid className={classes.grid} item xs={12} sm={6}>
                    <Button href="/risk-assesments">
                        <Avatar className={classes.avatar} src={riskImage} />
                    </Button>
                </Grid>
                <Grid className={classes.grid} item xs={12} sm={6}>
                    <Button href="/youth-outcomes">
                        <Avatar className={classes.avatar} src={surveyImage} />
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default Home;
