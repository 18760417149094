import React from 'react';
import Routes from './Routes/Routes';
import NavigationBar from './Components/NavigationBar/NavigationBar';

function App() {
    const auth = sessionStorage.getItem('token') !== null; // Used so we only display the toolbar when the user is authenticated
    return (
        <div>
            {auth && <NavigationBar />}
            <Routes />
        </div>
    );
}

export default App;
